:host {
  display: block;
  width: auto;
  top: 4px;

  .menu {
    display: inline-flex;
    width: 150px;
    font-weight: 700;
    height: 24px;
    font-size: 14px;
    align-items: center;

    mat-icon {
      width: 36px;
      height: 36px;
    }
  }

  ::ng-deep {
    button.mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
      background-color: unset !important;
    }

    .mat-mdc-menu-item {
      width: 36px !important;
      height: 24px !important;

      &:not([disabled]):hover {
        background-color: unset !important;
      }
    }
  }
}

.export-button {
  --mdc-icon-button-state-layer-size: 36px !important;
  top: 4px;
  padding: 0 !important;
  margin: 0 30px 0 0;

  &.mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
    background-color: unset !important;
  }

  mat-icon {
    width: 36px;
    height: 36px;
    line-height: 36px;
  }
}

.export-menu-panel .mat-mdc-menu-panel {
  width: 70px;
  max-width: unset;
  min-width: unset;

  .mat-mdc-menu-item {
    text-align: center;
  }
}
